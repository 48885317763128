// import './App.css';
import Home from "./page/home";
import FrontEnd from "./layouts/front-end/";
import BackEnd from "./layouts/back-end/";
import {BrowserRouter, Route, Switch, useLocation,Redirect} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Alert} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import MobxStore from "./mobx/mobx-store";
import StateContext, {useMobxState} from "./mobx/global-context";
import {checkLogin} from "./services/requester";
import Config from "./config";
import {ApolloClient, ApolloProvider, InMemoryCache, useQuery} from "@apollo/client";
import {GET_CURRENT_USER, GET_SYSTEM_CONFIG} from "./gql-query/select-query";
import {observer} from "mobx-react";
import RoleAssign from "./page/admin/role-assign";
import ExamSchedule from "./page/admin/exam-schedule";
import ExamScheduleDetail from "./page/admin/exam-schedule-detail";
import Dashboard from "./page/student/dashboard";
import {default as AdminDashboard} from "./page/admin/dashboard";
import StartExam from "./page/student/start-exam";
import FrontEndMini from "./layouts/front-end/index-mini";
import Student from "./page/admin/student";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {createTag} from "./services/helper";
import LoginLocal from "./page/student/login-local";
import Billing from "./page/admin/billing";
import ExamScheduleCourseSetting from "./page/admin/exam-schedule-course-setting";
import {Backdrop, CircularProgress, makeStyles} from "@material-ui/core";
import StudentExamSchedule from "./page/student/student-exam-schedule";
import axios from "axios";
import Register from "./page/student/register";
import RegisterCourse from "./page/student/register-course";
import Importer from "./page/admin/importer";
import AdminConfig from "./page/admin/admin-config";
import Bill from "./page/student/bill";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Wallet from "./page/student/wallet";
import useSessionChecker from "./components/session-checker";
import CourseScheduleCreateAndUpdate from "./components/course-schedule-create-and-update"; // Import css
import "react-datepicker/dist/react-datepicker.css";
import CheckIn from "./page/admin/check-in";
import CheckInStudent from "./page/admin/check-in-student";
import CheckInStudentGroup from "./page/admin/check-in-student-group";
import ExamScheduleParticipant from "./page/admin/exam-schedule-participant";
import ExamScheduleRoundParticipants from "./page/admin/exam-schedule-round-participants";
import Quiz from "./page/admin/quiz";
import FileSubmissionForm from "./components/quiz-type/file-submission/file-submission-form";
import FileSubmission from "./components/quiz-type/file-submission/file-submission";
import CourseScheduleReport from "./components/course-schedule-report";
import ExamReport from "./page/admin/exam-report";
import Certificated from "./page/student/certificated";
import OldExamData from "./page/student/old-exam-data";
import DqQuizExam from "./page/student/dq-quiz-exam";
import Profile from "./page/student/profile";
import Manual from "./page/student/manual";
import Certificate from "./page/admin/certificate";
import WorkshopQuiz from "./page/admin/workshop-quiz";
import ExamHistory from "./page/student/exam-history";
import  {default as IndexBT} from "./layouts/front-end-bt";
import MainContent from "./layouts/front-end-bt/components/main-content";
import Contact from "./page/student/contact";
import ExamScheduleParticipantsAll from "./components/exam-schedule-participants-all";
import GradeImporter from "./page/admin/grade-importer";
import {SocketProvider} from "./context/SocketProvider";
import moment from "moment";
import CheckInPrintStudent from "./page/admin/check-in-print-student";
// require('moment/locale/th.js');
moment.locale('th')
const My = ({user,systemConfig}) => {
    const {pathname} = useLocation();

    useEffect(() => {
        let redirect = Config.root + pathname;
        console.log('redirect=', redirect);
        if (user === null) {
            document.location.href = Config.api_url + '/login?redirect=' + redirect
        }
    }, [user])

    if (!user) return null
    return <>
        <FrontEndMini>
            <Switch>
                <Route exact path="/my" component={Dashboard}/>
                <Route exact path="/my/history" component={ExamHistory}/>
                <Route path="/my/examination/:id" component={StartExam}/>
                <Route path="/my/exam-schedule" component={StudentExamSchedule}/>
                <Route exact path="/my/register" component={RegisterCourse}/>
                <Route path="/my/register/calendar/:courseID/:scheduleID" component={Register}/>
                <Route path="/my/bill" component={Bill}/>
                {systemConfig?.wallet_enabled &&
                <Route path="/my/wallet" component={Wallet}/>
                }
                <Route path="/my/certificated" component={Certificated}/>
                <Route path="/my/old-exam-data" component={OldExamData}/>
                <Route path="/my/profile" component={Profile}/>
                <Redirect to="/my"/>
            </Switch>
        </FrontEndMini>
    </>
}


function App() {
    const client = new ApolloClient({
        uri: Config.api_url + '/graphql',
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        'myProgression': {
                            keyArgs: ['userID'],
                            maxAge: 5 * 60 * 1000,
                        },
                        'studentRegistrations': {
                            keyArgs: ['userID'],
                            maxAge: 5 * 60 * 1000,
                        },
                        'GET_COURSE':{
                            keyArgs:['register'],
                            maxAge: 5 * 60 * 1000,
                        },
                        'OldSchedules':{
                            maxAge: 5 * 60 * 1000,
                        }
                    },
                },
            }
        }),
        defaultOptions:{
            watchQuery:{
                fetchPolicy:'cache-and-network'
            },
            query:{
                fetchPolicy:'cache-first'
            }
        }
    });
    let {loading, error, data} = useQuery(GET_CURRENT_USER);
    let resConfig = useQuery(GET_SYSTEM_CONFIG,{fetchPolicy:'network-only'});

    const {data: userSession} = useSessionChecker();

    useEffect(() => {
        setTimeout(()=>{
          try{
              document.querySelectorAll('iframe').forEach((e) => {
                  console.log(e);
                  if (e.style.cssText.match(/position\: fixed; top\: 0px; left\: 0px; width\: 100%; height\: 100%; border\: none; z-index\: 214/ig)) {
                      e.remove();
                  }
              });
          }catch (e){
              console.log(e);
          }
        },3000);
    }, []);

    useEffect(() => {
        if (userSession === null) {
            MobxStore.currentUser = null;
        }else{
            MobxStore.currentUser = userSession;
        }
    }, [userSession]);

    useEffect(()=>{
        if(resConfig.data){
            MobxStore.systemConfig=resConfig.data.systemConfig;
        }
    },[resConfig.data])

    useEffect(() => {
        axios.get(Config.api_url + '/seb').then(res => {
            MobxStore.seb = res.data.seb;
        });
        if (!loading) {
            if (data && data.currentUser) {
                MobxStore.setUser(data.currentUser);
            } else {
                MobxStore.clearUser();
            }
        }
    }, [loading])

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));

    const classes = useStyles();

    if (loading || typeof MobxStore.seb === 'undefined' || !MobxStore.systemConfig) return <Alert variant='info'>Loading...</Alert>
    return <StateContext.Provider value={MobxStore}>
        <SocketProvider>
            <BrowserRouter basename={Config.root}>
                <Switch>
                    <Route exact path="/test" component={IndexBT}/>

                    <Route path="/admin">
                        {MobxStore.currentUser &&
                            <>
                                {
                                    MobxStore.currentUser?.role==='admin' ||
                                    MobxStore.currentUser?.role==='examiner' ||
                                    MobxStore.currentUser?.role==='signer' ||
                                    MobxStore.currentUser?.role==='finance'
                                        ?
                                        <BackEnd>
                                            <Switch>
                                                {MobxStore.currentUser?.role==='admin' &&
                                                    <>
                                                        <Route exact path="/admin" component={AdminDashboard}/>
                                                        <Route path="/admin/role-assign" component={RoleAssign}/>
                                                        <Route exact path="/admin/exam-schedule" component={ExamSchedule}/>
                                                        <Route exact path="/admin/exam-schedule/:id" component={ExamScheduleDetail}/>
                                                        <Route exact path="/admin/exam-schedule/:id/new" component={CourseScheduleCreateAndUpdate}/>
                                                        <Route exact path="/admin/exam-schedule/:id/participants/:scheduleID" component={ExamScheduleParticipant}/>
                                                        <Route exact path="/admin/exam-schedule/:id/participants/:scheduleID/all" component={ExamScheduleParticipantsAll}/>
                                                        <Route exact path="/admin/exam-schedule/:id/participants/:scheduleID/round/:roundID" component={ExamScheduleRoundParticipants}/>
                                                        <Route exact path="/admin/exam-schedule/:id/edit/:scheduleID">
                                                            <CourseScheduleCreateAndUpdate mode="edit"/>
                                                        </Route>
                                                        <Route path="/admin/exam-report" component={ExamReport}/>
                                                        <Route path="/admin/exam-schedule/:mdCourseID/report/:scheduleID" component={CourseScheduleReport}/>
                                                        <Route path="/admin/exam-schedule/:id/setting" component={ExamScheduleCourseSetting}/>
                                                        <Route path="/admin/import-participant" component={Importer}/>
                                                        <Route path="/admin/import-grade" component={GradeImporter}/>
                                                        <Route path="/admin/wk-quiz" component={WorkshopQuiz}/>
                                                        <Route path="/admin/config" component={AdminConfig}/>
                                                        <Route exact path="/admin/course/:courseID/quiz" component={Quiz}/>
                                                        <Route exact path="/admin/course/:courseID/question/file-submission" component={FileSubmission}/>
                                                        <Route path="/admin/course/:courseID/question/file-submission/:questionID" component={FileSubmission}/>
                                                        <Route path="/admin/student" component={Student}/>
                                                        <Route exact path="/admin/check-in" component={CheckIn}/>
                                                        <Route exact path="/admin/check-in/:roundID" component={CheckInStudent}/>
                                                        <Route exact path="/admin/check-in/print/:roundID" component={CheckInPrintStudent}/>
                                                        <Route path="/admin/check-in/:roundID/:checkInID" component={CheckInStudentGroup}/>
                                                        <Route path="/admin/billing" component={Billing}/>
                                                        <Route path="/admin/certificate" component={Certificate}/>
                                                    </>
                                                }
                                                {
                                                    MobxStore.currentUser?.role === 'examiner' &&
                                                    <>
                                                        <Route path="/admin/student" component={Student}/>
                                                        <Route exact path="/admin/check-in" component={CheckIn}/>
                                                        <Route exact path="/admin/check-in/:roundID" component={CheckInStudent}/>
                                                        <Route exact path="/admin/check-in/print/:roundID" component={CheckInPrintStudent}/>
                                                        <Route path="/admin/check-in/:roundID/:checkInID" component={CheckInStudentGroup}/>
                                                    </>
                                                }
                                                {
                                                    MobxStore.currentUser?.role === 'finance' &&
                                                    <Route path="/admin/billing" component={Billing}/>
                                                }
                                                {
                                                    MobxStore.currentUser?.role === 'signer' &&
                                                    <Route path="/admin/certificate" component={Certificate}/>
                                                }
                                            </Switch>
                                        </BackEnd>
                                        :
                                        <Redirect to="/my"/>
                                }
                            </>
                        }
                        {
                            MobxStore.currentUser === null &&
                            <Redirect to="/"/>
                        }
                    </Route>
                    {!MobxStore.currentUser &&
                        <Route path='/login-local'>
                            <FrontEndMini>
                                <LoginLocal/>
                            </FrontEndMini>
                        </Route>
                    }
                    <Route path="/my">
                        <My user={MobxStore.currentUser} systemConfig={MobxStore.systemConfig}/>
                    </Route>
                    <Route path="/">
                        <IndexBT>
                            <Route exact path="/" component={Home}/>
                            {/*<Route exact path="/" component={MainContent}/>*/}
                            <Route exact path="/manual" component={Manual}/>
                            <Route exact path="/contact" component={Contact}/>
                        </IndexBT>
                    </Route>
                </Switch>
                <ToastContainer/>
                <Backdrop className={classes.backdrop} open={MobxStore.backdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </BrowserRouter>
        </SocketProvider>
    </StateContext.Provider>
}

export default observer(App);
