import {io} from "socket.io-client";
import {useEffect, useRef, useState} from "react";
import pkg from '../../package.json';
import {
    Avatar,
    Box,
    Button, Card, CardContent, CardHeader,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import {Badge, Image} from "react-bootstrap";
import ModuleDisplay from "./module-display";
import {Alert} from "@material-ui/lab";
import Config from "../config";
import axios from "axios";
import {toast} from "react-toastify";
import MoodleIFrame from "./moodle-iframe";
import {useApolloClient} from "@apollo/client";
import {START_EXAM} from "../gql-query/select-query";
import QRCode from "react-qr-code";
import {getThaiDateBetween} from "../services/client-helper";
import {observer} from "mobx-react";
import {useMobxState} from "../mobx/global-context";
import {runInAction, toJS} from "mobx";
import {useHistory} from "react-router-dom";
import DqQuizExam from "../page/student/dq-quiz-exam";
import gql from "graphql-tag";
import {requestAPI} from "../services/requester";
import MoodleQuizDisplay from "./moodle-quiz-display";
import Countdown from "./countdown";
import {useSocket} from "../context/SocketProvider";
import * as mobx from "mobx";

const Peer = require('simple-peer');


const ClientCheckInProcess = ({registration, onReload}) => {

    const [started, setStarted] = useState(false);
    const [urlToken, setUrlToken] = useState();
    const apolloClient = useApolloClient();
    const state = useMobxState();
    const history = useHistory();
    const peer = useRef(null);
    const displayStream = useRef(null);
    const tmpSocket = useRef(null);
    const [sebLock, setSebLock] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedQuizIndex, setSelectedQuizIndex] = useState(0);
    const {socket,on,isConnected,disconnect} = useSocket();
    const {existedResults} = useMobxState();
    const client = useApolloClient();

    useEffect(() => {
        let results = mobx.toJS(existedResults);
        console.log('results=====',results);
    }, [existedResults]);



    useEffect(()=>{
        if(isConnected){
            console.log('Connected');
            // on("connect", () => {
            //     console.log(socket.id);
            // });
            // on("disconnect", () => {
            //     peer.current?.destroy();
            //     console.log('disconnect');
            // });
            on('reload', (data) => {
                if (typeof onReload == 'function') onReload();
            });
            on('message', (data) => {
                // console.log('message==', data);
                toast.warning(data);
            });
            on('reject', (data) => {
                toast.error('Reject by Admin, reason: ' + data.message);
                setStarted(false);
                if (typeof onReload == 'function') onReload();
            });
            on('auto-reject', (data) => {
                toast.error('You has left the video conference: ' + data.message);
                setStarted(false);
                if (typeof onReload == 'function') onReload();
            });
            on('admin-answer', ({sdp}) => {
                if (peer.current) {
                    // console.log('admin-answer===', sdp);
                    peer.current.signal(sdp);
                }
            });
            on('close-screen', () => {
                if (peer.current) {
                    peer.current.destroy();
                    peer.current = null;
                    stopStream(displayStream.current);
                    toast.info('Screen share stopped.');
                }
            });
            on('request-screen', async ({socketID}) => {
                toast.info('Admin requesting your screen.');
                try {
                    displayStream.current = await navigator.mediaDevices.getDisplayMedia({
                        video: true,
                        audio: false,
                    });
                    //if(peer.current)peer.current?.destroy();
                    peer.current = new Peer({
                        initiator: true,
                        trickle: false,
                        stream: displayStream.current
                    });

                    peer.current.on('signal', data => {
                        socket.emit('accepted-screen', {socketID, sdp: data});
                    });

                    peer.current.on('connect', async () => {
                        console.log("CONNECTED");
                        toast.success('Screen is sharing...');
                    });
                } catch (e) {
                    console.log(e);
                    console.log('send reject', socketID);
                    socket.emit('reject-screen', {socketID});
                }
            });

            on('quiz_attempt_submitted', data => {
                runInAction(() => {
                    state.exam = false;
                    state.examScheduleRound = null;
                    history.push('/my')
                    toast.success('Quiz submitted.');
                });
            })
            on('quiz_attempt_started', data => {
                toast.info('Quiz started.');
            })
        }else{
            peer.current?.destroy();
            console.log('disconnect');
        }
    },[isConnected])

    useEffect(() => {
        let socket;
        new Promise(async resolve => {
            if (registration.schedule?.useSeb) {
                try {
                    let resSeb = await requestAPI('GET', '/seb', null);
                    let {seb} = resSeb.data;
                    if (!seb) {
                        setSebLock(true);
                        // document.location.href=`seb://${Config.base_url}/dq.seb`;
                        resolve();
                    }
                } catch (e) {
                    resolve();
                }
            }
            // let resToken = await axios.post(`${Config.api_url}/generate-token`, null);
            // let token = resToken?.data?.token;
            // studentConnect(registration?.id,token);

            setLoading(false);

            // if (tmpSocket.current) tmpSocket.current?.disconnect();
            // new Promise(async resolve => {


                // socket = io(pkg.domain_name, {
                //     path: `/api/dq-socket`,
                //     reconnectionDelayMax: 10000,
                //     auth: {token},
                //     query: {
                //         "regID": registration?.id
                //     }
                // });
                //
                // socket.on("connect", () => {
                //     console.log(socket.id); // "G5p5..."
                // });
                // socket.on("disconnect", () => {
                //     peer.current?.destroy();
                //     console.log('disconnect');
                // });
                // socket.on('reload', (data) => {
                //     // console.log('reload==', data);
                //     if (typeof onReload == 'function') onReload();
                // });
                // socket.on('message', (data) => {
                //     // console.log('message==', data);
                //     toast.warning(data);
                // });
                // socket.on('reject', (data) => {
                //     toast.error('Reject by Admin, reason: ' + data.message);
                //     setStarted(false);
                //     if (typeof onReload == 'function') onReload();
                // });
                // socket.on('auto-reject', (data) => {
                //     toast.error('You has left the video conference: ' + data.message);
                //     setStarted(false);
                //     if (typeof onReload == 'function') onReload();
                // });
                // socket.on('admin-answer', ({sdp}) => {
                //     if (peer.current) {
                //         // console.log('admin-answer===', sdp);
                //         peer.current.signal(sdp);
                //     }
                // });
                // socket.on('close-screen', () => {
                //     if (peer.current) {
                //         peer.current.destroy();
                //         peer.current = null;
                //         stopStream(displayStream.current);
                //         toast.info('Screen share stopped.');
                //     }
                // });
                // socket.on('request-screen', async ({socketID}) => {
                //     toast.info('Admin requesting your screen.');
                //     try {
                //         displayStream.current = await navigator.mediaDevices.getDisplayMedia({
                //             video: true,
                //             audio: false,
                //         });
                //         //if(peer.current)peer.current?.destroy();
                //         peer.current = new Peer({
                //             initiator: true,
                //             trickle: false,
                //             stream: displayStream.current
                //         });
                //
                //         peer.current.on('signal', data => {
                //             socket.emit('accepted-screen', {socketID, sdp: data});
                //         });
                //
                //         peer.current.on('connect', async () => {
                //             console.log("CONNECTED");
                //             toast.success('Screen is sharing...');
                //         });
                //     } catch (e) {
                //         console.log(e);
                //         console.log('send reject', socketID);
                //         socket.emit('reject-screen', {socketID});
                //     }
                // });
                //
                // socket.on('quiz_attempt_submitted', data => {
                //     runInAction(() => {
                //         state.exam = false;
                //         state.examScheduleRound = null;
                //         history.push('/my')
                //         toast.success('Quiz submitted.');
                //     });
                // })
                // socket.on('quiz_attempt_started', data => {
                //     toast.info('Quiz started.');
                // })
                // tmpSocket.current = socket;
            //     resolve();
            // }).then(() => {
            // })
            resolve();
        });
        return () => {
            disconnect()
            new Promise(async resolve=>{
                try{
                    await client.resetStore();
                    await axios.get('/api/clear-cache');
                }catch (e){}
                resolve();
            }).then(()=>{})
            if(typeof socket==='object'){
                socket.disconnect();
                socket.removeAllListeners();
            }
            runInAction(()=>{
                state.examScheduleRound=void 0;
            })
            socket?.disconnect();
        }
    }, []);

    function stopStream(stream) {
        stream.getTracks().forEach(function (track) {
            if (track.readyState == 'live') {
                track.stop();
            }
        });
    }


    useEffect(() => {
        if (!registration?.checkIn?.approved) {
            setStarted(false);
        }
    }, [registration]);

    useEffect(() => {
        if (started && registration) {
            new Promise(async resolve => {
                try {
                    runInAction(() => state.backdrop = true);
                    await axios.get('https://dq.kku.ac.th/exam/local/dq/logout.php');
                    let resExamToken = await apolloClient.query({
                        query: START_EXAM,
                        variables: {
                            id: registration.id
                        },
                        fetchPolicy: 'network-only'
                    });
                    let startExam = resExamToken.data?.startExam;
                    await axios.get(`https://dq.kku.ac.th/exam/login/?token=${startExam?.token}&url=/`);
                    console.log('resExamToken===', startExam);
                    setUrlToken(startExam);
                } catch (e) {
                    console.log(e);
                    setStarted(false);
                } finally {
                    runInAction(() => state.backdrop = false);
                }
            })
        } else {
            setUrlToken(null);
        }
    }, [started]);

    function getTime() {
        return (new Date()).getTime() + state.serverTimeDiff;
    }

    async function startMoodleQuiz(){
        setStarted(true);
    }

    if (started && urlToken) {
        if (urlToken == 'dq-workshop') {
            return <DqQuizExam regID={registration.id}/>;
        } else {
            // if (urlToken?.quizURL.length > 1) {
                return <MoodleQuizDisplay quizURLs={urlToken?.quizURL}
                                          selectedQuizIndex={selectedQuizIndex}
                                          onChange={setSelectedQuizIndex}
                                          registration={registration}
                />
                // return <Paper>
                //     <Grid container>
                //         {urlToken.quizURL.map((qUrl,n)=>
                //             <Grid key={qUrl.quiz.id} item xs={6} md={4}>
                //                 <Card>
                //                     <CardHeader
                //                         avatar={
                //                             <Avatar aria-label="recipe">
                //                                 {qUrl.dqQuiz?.name?.substr(0,1)}
                //                             </Avatar>
                //                         }
                //                         title={qUrl.dqQuiz.name}
                //                     />
                //                     <CardContent>
                //                         <Button variant='contained' color='default' onClick={async e=>{
                //                             // let token = await requestAPI('POST','/generate-token');
                //                             // console.log('TOKEN==', token);
                //                             console.log('n==',n,urlToken?.quizURL[n].url);
                //                             setSelectedQuizIndex(n)
                //                         }}>Open Quiz</Button>
                //                     </CardContent>
                //                 </Card>
                //             </Grid>
                //         )}
                //     </Grid>
                //     <Box pt={2}>
                //         <Grid container>
                //             <Grid item xs={12}>
                //                 {urlToken?.quizURL[selectedQuizIndex].url &&
                //                 <MoodleIFrame url={urlToken?.quizURL[selectedQuizIndex].url}/>
                //                 }
                //             </Grid>
                //         </Grid>
                //     </Box>
                // </Paper>
            // } else {
            //     return <MoodleIFrame url={urlToken?.quizURL?.[0].url}/>;
            // }
        }
    }
    if (loading) return <Alert severity='info' color='primary'>Loading...</Alert>
    if (sebLock) return <>
        <Alert severity='error'>Safe exam browser is required.</Alert>
        <Box p={2} display='flex' justifyContent='center'>
            <Button variant='contained' color='primary' onClick={() => {
                document.location.href = `seb://${Config.base_url}/dq.seb`;
            }}>Start Safe exam browser</Button>
        </Box>
    </>
    return <Paper>
        <Box p={2} display="flex" justifyContent='center'>
            <div style={{width: 850}}>
                <Grid container>
                    {state.currentUser.studentID ?
                        <Grid item xs={12} md={4}>
                            <div className="text-center">
                                <Box><Image fluid src={`${Config.api_url}/profile_picture`}/></Box>
                            </div>
                        </Grid>
                        :
                        null
                    }
                    <Grid item xs={12} md={state.currentUser.studentID ? 8 : 12}>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Student ID</TableCell>
                                        <TableCell>{registration.user.studentID}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>{registration.user.fullname}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Email</TableCell>
                                        <TableCell>{registration.user.email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Course</TableCell>
                                        <TableCell>{registration.course.name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Schedule</TableCell>
                                        <TableCell>{registration?.schedule?.title}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Quiz</TableCell>
                                        <TableCell>
                                            <ModuleDisplay
                                            quizSet={registration?.scheduleRound?.quizSet}/>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Exam Date</TableCell>
                                        <TableCell>{getThaiDateBetween(registration?.scheduleRound?.examStart, registration?.scheduleRound?.examEnd, 'date-time')}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Time limit</TableCell>
                                        <TableCell>{registration?.scheduleRound?.quizSet.minutes} Minutes</TableCell>
                                    </TableRow>
                                    {registration?.examResult?.examStat == 'quiz_attempt_submitted' ?
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <Box style={{textAlign: "center"}}>
                                                    <h4>Your score result</h4>
                                                    <Alert severity='success'>
                                                        {registration?.examResult?.score}%
                                                    </Alert>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <>
                                            <TableRow>
                                                <TableCell>Group</TableCell>
                                                <TableCell><span
                                                    className="text-capitalize">{registration?.checkIn?.scheduleRoundCheckIn?.groupName}</span></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                {registration?.checkIn?.scheduleRoundCheckIn?.googleMeetURL ?
                                                    <>
                                                    {
                                                        !state.examExpired &&
                                                        <TableCell colSpan="2">
                                                            <Box style={{textAlign: 'center'}}>
                                                                <h4>Meeting URL for check-in</h4>
                                                            </Box>
                                                            <Box mt={2} style={{textAlign: 'center'}}>
                                                                <QRCode
                                                                    value={registration?.checkIn?.scheduleRoundCheckIn?.googleMeetURL}
                                                                    size={100}/>
                                                                <Box
                                                                    mt={1}>{registration?.checkIn?.scheduleRoundCheckIn?.googleMeetURL}</Box>
                                                            </Box>
                                                        </TableCell>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                        {!state.examExpired &&
                                                        <>
                                                            <TableCell>Meeting URL</TableCell>
                                                            <TableCell><Alert
                                                                severity="info">Waiting...</Alert></TableCell>
                                                        </>
                                                        }
                                                    </>

                                                }
                                            </TableRow>
                                            {registration?.checkIn?.approved ?
                                                <>
                                                    <TableRow>
                                                        <TableCell colSpan={2}>
                                                            <Alert severity='success'>Approved.</Alert>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2}>
                                                            <div className="text-center">
                                                                {state.examExpired &&
                                                                <Box mb={2}>
                                                                    <Alert severity="error">Examination was
                                                                        expired.</Alert>
                                                                </Box>
                                                                }
                                                                {getTime() >= registration.scheduleRound.examStart ?
                                                                    <Button disabled={state.examExpired}
                                                                            variant="contained" color='primary'
                                                                            onClick={e => startMoodleQuiz()}>Start
                                                                        examination</Button>
                                                                    :
                                                                    <Button disabled variant="contained"
                                                                            color='primary'>Waiting to start ( <Countdown style={{fontSize:'.8rem'}} showDay={false} expireTimestamp={registration?.scheduleRound?.examStart}/> )</Button>
                                                                }
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        {state.examExpired ?
                                                            <Alert severity="error">Examination was expired.</Alert>
                                                            :
                                                            <Alert severity='info'>Waiting for admin approve.</Alert>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
        </Box>
    </Paper>
}
export default observer(ClientCheckInProcess);
