import {makeAutoObservable} from "mobx";
import {logout as logoutRequest} from "../services/requester";
import Config from "../config";
import axios from "axios";
import {GET_CURRENT_USER, GET_SYSTEM_CONFIG} from "../gql-query/select-query";
import {SAVE_SYSTEM_CONFIG} from "../gql-query/save-query";
import {getServerTime} from "../services/client-helper";

class MobxStore {
    currentUser = void 0;
    currentSideMenu={overlay:''}

    cacheUpdate= {};

    getCacheLastUpdate(redisName){
        return this.cacheUpdate?.[redisName] || 0;
    }
    setCacheUpdate(redisName,timestamp){
        this.cacheUpdate[redisName]=timestamp;
    }

    systemConfig = null;

    studentProgression = void 0;

    existedResults = void 0;

    mongooseID = [];

    examExpired=false;

    backdrop=false;

    seb = void 0;

    exam = false;
    examScheduleRound=null;

    oldExamData=null;

    currentOperation=null;

    ///CHECK-IN
    checkInFormData={};
    checkInEvents=[];
    serverTimeDiff=0;

    //////REGISTER
    registerQuizSet=[];
    registerSelectedRounds=[];
    registerStep1Data={};
    registerStep2Data={};
    registerStep3Data={};
    //////REGISTER END

    adminMoodleSession = void 0;

    serverTime=new Date();

    constructor() {
        makeAutoObservable(this);
    }

    reloadUserConfig(apolloClient){
        return new Promise(async (resolve, reject) => {
            try{
                let resUser = await apolloClient.query({query:GET_CURRENT_USER,fetchPolicy:'network-only'});
                let resConfig = await apolloClient.query({query:GET_SYSTEM_CONFIG,fetchPolicy:'network-only'});
                this.currentUser=resUser.data.currentUser;
                this.systemConfig=resConfig.data.systemConfig;
                resolve(true);
            }catch(e){
                reject(e);
            }
        })
    }

    updateServerTime(){
        getServerTime().then(time=>{
            this.serverTime=time;
        })
    }

    resetRegisterData(){
        this.registerQuizSet=[];
        this.registerSelectedRounds=[];
        this.registerStep1Data={};
        this.registerStep2Data={};
        this.registerStep3Data={};
        this.registerStepIsFinished=false;
    }

    sideMenu(state){
        if(state=='show'){
            window.$('body').attr('class','vertical-layout navbar-floating footer-static vertical-overlay-menu pace-done menu-open')
            this.currentSideMenu.overlay=' show'
        }else if(state=='show-menu-only'){
            window.$('body').attr('class','vertical-layout navbar-floating footer-static vertical-overlay-menu pace-done menu-open')
            this.currentSideMenu.overlay=''
        }else{
            window.$('body').attr('class','vertical-layout navbar-floating footer-static vertical-overlay-menu pace-done menu-hide')
            window.$('body').addClass('menu-hide')
            this.currentSideMenu.overlay=''
        }
    }

    setUser(user){
        this.currentUser=user;
    }

    clearUser(){
        this.currentUser=null;
    }

    logout(){
        if(this.seb){
            document.location.href='https://exit'
            return;
        }
        const clearSession=()=>{
            if(document.location.href.match(/\/admin/) ||
                document.location.href.match(/\/my/)
            ){
                document.location.href=Config.root
            }else{
                this.clearUser();
            }
        }
        logoutRequest().then(()=>{
            axios.get( `${Config.moodleURL}/auth/xtoken/api.php?action=logout`);
            if(this.currentUser && this.currentUser.authType==='sso'){
                let ssoLogout = window.open(null,'sso-logout');
                ssoLogout.document.location.href='https://sso.kku.ac.th/saml2/idp/SingleLogoutService.php';
                setTimeout(()=>{
                    ssoLogout.close();
                    clearSession();
                },2000);
            }else{
                clearSession();
            }
        }).catch(e=>{
            console.log(e.message);
        });
    }
}



export default new MobxStore();
