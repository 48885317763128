import {Link, NavLink} from "react-router-dom";
import Config from "../../config";
import axios from "axios";
import {useContext, useEffect, useState} from "react";
import {useMobxState} from "../../mobx/global-context";
import LiNav from "./li-nav";
import {
    faHome, faBook, faCalendar, faUsersCog,
    faUserGraduate, faUserCheck, faFileInvoiceDollar, faCog, faSignOutAlt, faFileImport,
    faColumns,faEdit
}
    from '@fortawesome/free-solid-svg-icons'
import AssessmentIcon from '@material-ui/icons/Assessment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import {useHistory} from 'react-router-dom'
import useMoodleLogin from "../../hooks/moodle-login";
import {runInAction} from "mobx";
import {observer} from "mobx-react";
import {toast} from "react-toastify";
const LiNavRole =({to,currentRole,allowedRole=[],exact=false,icon,children})=>{
    if(allowedRole.includes(currentRole)){
        return <LiNav to={to} exact={exact} icon={icon}>{children}</LiNav>
    }
    return;
}
const LeftMenu = ({onLogout}) => {
    const state = useMobxState();
    const [mdLoading,setMdLoading] = useState(false);
    const history = useHistory();
    const [moodleLogined,sync] = useMoodleLogin();

    // async function moodle(e){
    //     e.preventDefault();
    //     if(mdLoading)return;
    //     setMdLoading(true);
    //     await startMoodle('/course');
    //     setMdLoading(false);
    // }
    useEffect(()=>{
        let fn =async ()=>{
            await sync();
        }
        fn();
        window.addEventListener('focus', fn);
        return ()=>{
            window.removeEventListener('focus', fn);
        }
    },[])

    useEffect(()=>{
        runInAction(()=>{
            state.adminMoodleSession=moodleLogined;
        });
    },[moodleLogined])

    useEffect(()=>{
        switch(state.currentUser.role){
            case 'admin':
                // history.push('/admin');
                break;
            case 'examiner':
                // history.push('/admin/check-in');
                break;
            case 'finance':
                // history.push('/admin/billing');
                break;

        }
    },[state.currentUser.role]);

    const {role} = state.currentUser;
    return <>
        <div className="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true"
            style={{overflowY:'auto'}}
        >
            <div className="navbar-header">
                <ul className="nav navbar-nav flex-row">
                    <li className="nav-item mr-auto"><a className="navbar-brand"
                                                        href={void 0}><span
                        className="brand-logo">
                            </span>
                        <h2 className="brand-text">DQ Administrator</h2>
                    </a></li>
                    <li className="nav-item nav-toggle d-block d-xl-none"><a className="nav-link modern-nav-toggle pr-0"
                                                           data-toggle="collapse" onClick={e => {
                        state.sideMenu('hide')
                    }}>X</a></li>
                </ul>
            </div>
            <div className="shadow-bottom"></div>
            <div className="main-menu-content">
                <ul className="navigation navigation-main" id="main-menu-navigation">
                    {role=='admin' &&
                        <>
                            <LiNav to="/admin" exact icon={faHome}>Dashboard</LiNav>
                            {state.adminMoodleSession?
                                <LiNav to={Config.moodleURL} external icon={faBook}>Start Moodle</LiNav>
                                :
                                <LiNav to="/api/start-moodle" external icon={faBook}>Start Moodle</LiNav>
                            }

                            <LiNav to="/" external icon={faColumns}>Front Page</LiNav>
                            <li className=" navigation-header"><span >Examination</span><i
                                data-feather="more-horizontal"/>
                            </li>
                            <LiNav to="/admin/exam-schedule" icon={faCalendar}>Courses</LiNav>
                            <LiNav to="/admin/student" icon={faUserGraduate}>Student</LiNav>
                            <LiNav to="/admin/import-participant" icon={faFileImport}>Importer</LiNav>
                            <LiNav to="/admin/import-grade" icon={faFileImport}>Grade Importer</LiNav>
                            <LiNav to="/admin/check-in" icon={faUserCheck}>Check-In</LiNav>
                            {/*<LiNav to="/admin/wk-quiz" icon={faEdit}>Workshop Quiz</LiNav>*/}
                            <li className=" navigation-header"><span >Report</span><i
                                data-feather="more-horizontal"/>
                            </li>
                            <LiNav to="/admin/exam-report" muiIcon={<AssessmentIcon/>}>Exam Result</LiNav>
                            <LiNav to="/admin/billing" icon={faFileInvoiceDollar}>Billing</LiNav>
                            <LiNav to="/admin/certificate" muiIcon={<ReceiptIcon/>}>Certificate</LiNav>
                            <li className=" navigation-header"><span>Administrator</span><i
                                data-feather="more-horizontal"/>
                            </li>
                            <LiNav to="/admin/config" icon={faCog}>System Config</LiNav>
                            <LiNav to="/admin/role-assign" icon={faUsersCog}>Role Assignment</LiNav>
                        </>
                    }
                    {role=='examiner' &&
                        <>
                            <li className=" navigation-header"><span >Examination</span><i
                                data-feather="more-horizontal"/>
                            </li>
                            <LiNav to="/admin/student" icon={faUserGraduate}>Student</LiNav>
                            <LiNav to="/admin/check-in" icon={faUserCheck}>Check-In</LiNav>
                        </>
                    }
                    {role=='finance' &&
                        <>
                            <li className=" navigation-header"><span >Report</span><i
                                data-feather="more-horizontal"/>
                            </li>
                            <LiNav to="/admin/student" icon={faUserGraduate}>Student</LiNav>
                            <LiNav to="/admin/billing" icon={faFileInvoiceDollar}>Billing</LiNav>
                        </>
                    }
                    {role=='signer' &&
                        <>
                            <LiNav to="/admin/student" icon={faUserGraduate}>Student</LiNav>
                            <LiNav to="/admin/certificate" muiIcon={<ReceiptIcon/>}>Certificate</LiNav>
                        </>
                    }
                    <li className=" navigation-header"><span>System</span><i
                        data-feather="more-horizontal"/>
                    </li>
                    <LiNav external icon={faSignOutAlt} onClick={onLogout} onClick={e=>{
                        axios.get('/api/clear-server-cache').then(()=>{
                            toast.success('Server Cache Cleared');
                        }).catch(()=>{});
                    }}>Clear Server Cached</LiNav>
                    <LiNav external icon={faSignOutAlt} onClick={onLogout} onClick={e=>{
                        state.logout();
                    }}>Logout</LiNav>
                </ul>
            </div>
        </div>
    </>;
}
export default observer(LeftMenu);
